/* eslint-disable no-unused-expressions */
import React, { useCallback, useEffect, useRef, useState } from 'react';
// import { useTranslation } from "react-i18next";
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import { useParams } from 'react-router-dom';
import { Container, Footer, Text, Right, Left, FullRow, RNCContainer, Button, Corrective, FooterContainer, FooterErrorContainer } from './styles';
import { DefaultTheme, ThemeProvider } from 'styled-components';
import * as Yup from 'yup';
import { useTranslation } from "react-i18next";
import filesize from 'filesize';

import Input from '../../components/Input';
import TextArea from '../../components/TextArea';
import getValidationErrors from '../../utils/getValidationErrors';
import api from '../../services/api';
import addDays from '../../utils/addDays';
import Header from '../../components/Header';
import AnimatedIcon from '../../components/AnimatedIcon';
import Section from '../../components/Section';

import usePersistedState from '../../utils/usePersistedState';
import GlobalStyle from '../../styles/global';
import dark from '../../styles/themes/dark';
import light from '../../styles/themes/light';
import DatePicker from '../../components/DatePicker';
import CreatableSelect from '../../components/Select';
import isEmpty from '../../utils/isEmpty';
import Upload from '../../components/Upload';
import FileList from '../../components/FileList';
import api2 from '../../services/api copy';
import DownloadList from '../../components/DownloadList';

import { Auth, Storage } from 'aws-amplify';

interface fillRNC {
  RNC: {
    id: string;
    C_RNCID: string;
    Description: string;
    C_Evidence: string;
    C_Comments: string;
    C_PMResponsible: {
      Name: string
    };
    CreatedBy: {
      Name: string;
    };
    C_ProjectRNC: {
      Name: string;
      C_PEP: string;
    };
    C_NCType: {
      Name: string;
    };
    C_Critical: {
      Name: string;
    };
    C_ChangeType: {
      Name: string;
    };
    C_Recurrence: {
      Name: string;
    };
    C_LimitDateforResolution: Date;
    C_AreaGroup: {
      Name: string;
    };
    C_EquipmentServices: {
      Name: string;
    };
    C_PurchaseOrderNumber: string;
    C_RNCCategory: {
      Name: string;
    };
    C_NotifiedResponsibleName: string;
    C_RNCOccurrenceDate: Date;
    C_ImmediateActionName: string;
    C_ImmediateActionDescription: string;
    C_ImplementationImmediateAction: {
      id: string;
    };
    C_ImmediateActionDueDateNCC: Date;
    C_RootCauseAnalysis: string;
    C_QualityToolMethod: {
      id: string;
      Name: string;
    };
    C_CauseAnalysisResponsibleRnc: {
      Name: string;
    };
    C_RootCauseAnalysisLimitDate: Date;
    C_CorrectiveActionEffectiveness: boolean;
    C_CorrectiveActionEffectivenessDate: Date;
    C_CorrectiveEffectivnessResponsible: {
      Name: string;
    };
    C_EffectivenessEvidence: string;
    C_EffectivenessComments: string;
  };
  C_Implementation: Array<{
    id: string;
    Name: string;
  }>;
  C_EquipmentServices: Array<{
    Name: string;
  }>;
  C_QualityToolMethod: Array<{
    id: string;
    Name: string;
  }>;
  CorrectiveActions: Array<{
    id: string;
    Name: string;
    C_Responsible: {
      Name: string;
    };
    Description: string;
    DueDate: Date;
    C_Evidence: string;
  }>
}

interface answeredRNC {
  Name: string;
  C_RNCID: string;
  CreatedBy: {
    Name: string;
  };
  C_NotifiedResponsibleName: string;
  C_AlreadyAnswered: Boolean | null;
  CreatedOn: string;
  C_Critical: {
    Name: string;
  };
}

interface updateRNC {
  id: string;
  description: string;
  evidence: string;
  purchaseOrderNumber: string;
  comments: string;
  actionName: string;
  implementationImmediateAction: string;
  actionDescription: string;
  immediateActionDueDate: Date;
  rootCauseAnalysis: string;
  qualityToolMethod: string;
  rootCauseAnalysisLimitDate: Date;
  correctiveActionComments?: string;
  correctiveAction?: Array<{
    id: string;
    Name: string;
    Responsible: string;
    Description: string;
    DueDate: Date;
    Evidence: string;
  }>;
  correctiveActionCreate?: {
    Name: string;
    Description: string;
    DueDate: Date;
    Evidence: string;
    Comments: string;
  }
  effectiveness: boolean;
  effectivenessDate: Date;
  effectivenessEvidence: string;
  effectivenessComments: string;
}

interface FileProps {
  file: File;
  name: string;
  readableSize: string;
}


const UpdateRNC: React.FC = () => {
  const [Theme, setTheme] = usePersistedState<DefaultTheme>('theme', light);
  const toggleTheme = () => {
    setTheme(Theme.title === 'light' ? dark : light)
  }

  const { t } = useTranslation();

  const { id } = useParams<any>();
  const formRef = useRef<FormHandles>(null);
  const [InfoRNC, setInfoRNC] = useState<fillRNC>({} as fillRNC);
  const [AnsRNC, setAnsRNC] = useState<answeredRNC>({} as answeredRNC); // RNC-Info
  const [Answered, setAnswered] = useState<Boolean>(false); // guarda se o relatório já foi respondido
  const [IsExpired, setIsExpired] = useState<Boolean>(false); // guarda se está expirado
  const [IsSuccessfull, setIsSuccessfull] = useState<Boolean>(false); // guarda se o Report foi respondido corretamente
  const [IsError, setIsError] = useState<Boolean>(false); // guarda se ocorreu algum erro
  const [Found, setFound] = useState<Boolean>(); // guarda se foi encontrado o Report

  // useState dos picklists
  const [Implementation, setImplementation] = useState<any>([]);
  const [QualityToolMethod, setQualityToolMethod] = useState<any>([]);
  const [Disabled, setDisabled] = useState<any>(false);

  const [ShowWarning, setShowWarning] = useState<any>(false);
  const [UploadedFiles, setUploadedFiles] = useState<FileProps[]>([]);
  const [DownloadFiles, setDownloadFiles] = useState<any>();

  function submitFile(files: File[]): void {
    console.log("entrou");

    const uploadFiles = files.map<FileProps>(file => ({
      file,
      name: file.name,
      readableSize: filesize(file.size),
    }));

    setUploadedFiles(uploadFiles);
    console.log(UploadedFiles);

  }

  useEffect(() => {
    setShowWarning(false);
    document.title = "Evoluenergy - RNC"
    async function loadTransactions(): Promise<void> {
      try {
        // Faz uma busca no Clarizen e verifica se a pesquisa é válida(já respondido, data de expiração)
        const answeredResp = await api.get(`/rnc-info/${id}`);
        const downloadableItems = await api.get(`/upload/${id}`);
        setDownloadFiles(downloadableItems.data);
        console.log(downloadableItems.data);

        const ansResp: answeredRNC = answeredResp.data;
        setAnsRNC(ansResp);
        if (!answeredResp.data.hasOwnProperty('error')) {
          setFound(true);
          const rncAnsweredResp: answeredRNC = answeredResp.data;

          // set data de expiração
          let limitDate: Date = addDays(
            new Date(rncAnsweredResp.CreatedOn),
            rncAnsweredResp?.C_Critical != null && rncAnsweredResp?.C_Critical?.Name === 'Impeditive' ? 5 : 10
          );
          let today: Date = new Date();

          setAnswered(false);

          if (rncAnsweredResp.C_AlreadyAnswered !== true && !(today.getTime() > limitDate.getTime())) {
            const response = await api.get(`/rnc/${id}`);
            const rncResp: fillRNC = response.data;
            setInfoRNC(rncResp);
            setImplementation(
              rncResp!.C_Implementation!.map(implementation => ({ value: implementation.id, name: implementation.Name }))
            );
            setQualityToolMethod(
              rncResp!.C_QualityToolMethod!.map(qtm => ({ value: qtm.id, name: qtm.Name }))
            );
          }

          // valida se está expirado ou não
          else if (today.getTime() > limitDate.getTime()) {
            setIsExpired(true);
          }

          // valida se já está respondido
          if (rncAnsweredResp.C_AlreadyAnswered === true) {
            setAnswered(rncAnsweredResp.C_AlreadyAnswered)
          }
        } else {
          setFound(false);
        }
      } catch (err) {
        setFound(false);
      }
    }

    loadTransactions();
  }, [id]);

  const handleSubmit = useCallback(
    async (data: updateRNC) => {
      setDisabled(true);
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({

          purchaseOrderNumber: Yup.string().max(2000, t("WarningMaxChar")),
          comments: Yup.string().max(2000, t("WarningMaxChar")),

          //Ação Imediata
          actionName: Yup.string().max(2000, t("WarningMaxChar")),
          implementationImmediateAction: Yup.string().required(t("WarningImplementationImmediateAction")).max(2000, t("WarningMaxChar")),
          actionDescription: Yup.string().max(2000, t("WarningMaxChar")),
          immediateActionDueDate: Yup.date().required(t("WarningImmediateActionDueDate")).nullable(),

          // Cause Analysis
          rootCauseAnalysis: Yup.string().max(2000, t("WarningMaxChar")),
          causeAnalysisResponsible: Yup.string().max(2000, t("WarningMaxChar")),
          qualityToolMethod: Yup.string().max(2000, t("WarningMaxChar")),
          // rootCauseAnalysisLimitDate: Yup.date().required('The field Root Cause Analysis Limit Date is mandatory').nullable(),

          //Action Plan Corrective
          correctiveAction: Yup.array().of(Yup.object().shape({
            Name: Yup.string().max(2000, t("WarningMaxChar")),
            Description: Yup.string().max(2000, t("WarningMaxChar")),
            Evidence: Yup.string().max(2000, t("WarningMaxChar")),
          })).min(0).max(5),

          //Effectiveness
          effectivenessResponsible: Yup.string().max(2000, t("WarningMaxChar")),
          effectivenessEvidence: Yup.string().max(2000, t("WarningMaxChar")),
          effectivenessComments: Yup.string().max(2000, t("WarningMaxChar")),
        });

        await schema.validate(data, {
          abortEarly: false,
        });


        const {
          description,
          evidence,
          purchaseOrderNumber,
          comments,

          // Immediate Action
          actionName,
          implementationImmediateAction,
          actionDescription,
          immediateActionDueDate,

          // Cause Analysis
          rootCauseAnalysis,
          qualityToolMethod,
          rootCauseAnalysisLimitDate,

          // Action Plan Corrective
          correctiveAction,
          correctiveActionCreate,
          correctiveActionComments,

          // Effectiveness
          effectiveness,
          effectivenessDate,
          effectivenessEvidence,
          effectivenessComments
        } = data;
        console.log(data);

        let updateObj: updateRNC = {
          id: InfoRNC.RNC.id,
          description,
          evidence,
          purchaseOrderNumber,
          comments,

          //Immediate Action
          actionName,
          implementationImmediateAction,
          actionDescription,
          immediateActionDueDate,

          // Cause Analysis
          rootCauseAnalysis,
          qualityToolMethod,
          rootCauseAnalysisLimitDate,

          //Effectiveness
          effectiveness,
          effectivenessDate,
          effectivenessEvidence,
          effectivenessComments
        };

          console.log(InfoRNC.CorrectiveActions);
        if (correctiveAction != null && correctiveAction.length >= 1 && correctiveAction.length <= 5) {
          let newCorrectiveAction = correctiveAction.map((ca_item, index) => ({
            id: InfoRNC.CorrectiveActions.slice(Math.max(InfoRNC.CorrectiveActions.length - 5, 0))[index].id,
            Name: ca_item.Name,
            Responsible: ca_item.Responsible,
            DueDate: ca_item.DueDate,
            Evidence: ca_item.Evidence,
            Description: ca_item.Description
          }));

          updateObj.correctiveAction = newCorrectiveAction
        }

          console.log(InfoRNC.CorrectiveActions);
        console.log(correctiveActionComments);

        if (correctiveActionComments !== null && correctiveActionComments !== "") {
          updateObj.correctiveActionComments = correctiveActionComments;
        }

        if (correctiveActionCreate != null) {
          updateObj.correctiveActionCreate = correctiveActionCreate;
        }

        console.log(updateObj)
        const respUpdate = await api.post('/rnc', updateObj);

        // if (UploadedFiles.length) {
        //   const file = UploadedFiles[0];
        //   try {
        //     const result = await Storage.put(file.name, file.file);
        //     const respUpdate = await api2.post('/items', {
        //       "fileName": result.key,
        //       "id": "/C_NCC/3m5rg3tbg9u8k9mi3cp63z17a168"
        //     });
        //     console.log(respUpdate);
        //   } catch (err) {
        //     console.log(err);
        //   }
        // }
        // console.log(respUpdate);

        if (!respUpdate.data.hasOwnProperty('errorCode')) {
          setIsSuccessfull(true);
        } else {
          setIsError(true);
        }
      } catch (err) {

        if (err instanceof Yup.ValidationError) {
          setDisabled(false);
          setShowWarning(true);
          const errors = getValidationErrors(err);
          console.log(errors);
          formRef.current?.setErrors(errors);
        } else if (err.hasOwnProperty('message') && err.message == "Endpoint request timed out") {
          console.log(err.message);
          setIsSuccessfull(true);
        } else {
          console.log(err);
          setIsSuccessfull(false);
        }
      }
    },
    [InfoRNC, UploadedFiles],
  );

  return (
    <ThemeProvider theme={Theme}>
      <GlobalStyle />
      <Header size="small" toggleTheme={toggleTheme} theme={Theme} />
      <Container>
        <RNCContainer>
          {(!isEmpty(InfoRNC) && Answered !== true && Found === true && IsExpired === false && IsSuccessfull === false && IsError === false) && (
            <>
              <Form ref={formRef} onSubmit={handleSubmit}>

                <Left>
                  <Input
                    type="text"
                    name="rncId"
                    LabelName="NC ID"
                    defaultValue={InfoRNC.RNC?.C_RNCID}
                    disabled
                    isFillable={false}
                    isHalf={true}
                    icon={null}
                  />
                  <Input
                    type="text"
                    name="buSegment"
                    LabelName={t("BU Segment")}
                    defaultValue={InfoRNC.RNC.C_AreaGroup?.Name}
                    disabled
                    isFillable={false}
                    isHalf={true}
                    icon={null}
                  />
                  <Input
                    type="text"
                    name="project"
                    LabelName={t("Project")}
                    defaultValue={InfoRNC.RNC.C_ProjectRNC?.Name}
                    disabled
                    isFillable={false}
                    isHalf={true}
                    icon={null}
                  />
                </Left>
                <Right>
                  <Input
                    type="text"
                    name="critical"
                    LabelName={t("Critical")}
                    defaultValue={InfoRNC.RNC.C_Critical?.Name}
                    disabled
                    isFillable={false}
                    isHalf={true}
                    icon={null}
                  />
                  <Input
                    type="text"
                    name="purchaseOrderNumber"
                    LabelName={t("Purchase Order Number")}
                    defaultValue={InfoRNC.RNC.C_PurchaseOrderNumber}
                    isFillable={InfoRNC.RNC.C_PurchaseOrderNumber !== "" && InfoRNC.RNC.C_PurchaseOrderNumber !== null ? false : true}
                    disabled={InfoRNC.RNC.C_PurchaseOrderNumber !== "" && InfoRNC.RNC.C_PurchaseOrderNumber !== null ? true : false}
                    isHalf={true}
                    icon={null}
                  />
                  <Input
                    type="text"
                    name="pep"
                    LabelName="PEP"
                    defaultValue={InfoRNC.RNC.C_ProjectRNC?.C_PEP}
                    disabled
                    isFillable={false}
                    isHalf={true}
                    icon={null}
                  />
                </Right>
                <TextArea
                  placeholder=""
                  name="description"
                  LabelName={t("Description")}
                  defaultValue={InfoRNC.RNC.Description}
                  isFillable={true}
                  isHalf={false}
                  icon={null}
                />
                <TextArea
                  placeholder=""
                  name="evidence"
                  LabelName={t("Evidence")}
                  defaultValue={InfoRNC.RNC.C_Evidence}
                  isFillable={true}
                  isHalf={false}
                  icon={null}
                />

                <Left>
                  <Input
                    type="text"
                    name="pm"
                    LabelName="PM"
                    defaultValue={InfoRNC.RNC.C_PMResponsible?.Name}
                    disabled
                    isFillable={false}
                    isHalf={true}
                    icon={null}
                  />
                  <Input
                    type="text"
                    name="ncType"
                    LabelName={t("NC Type")}
                    defaultValue={InfoRNC.RNC.C_NCType?.Name}
                    disabled
                    isFillable={false}
                    isHalf={true}
                    icon={null}
                  />
                  <Input
                    type="text"
                    name="equipmentServices"
                    LabelName={t("Equipment / Services")}
                    defaultValue={InfoRNC.RNC?.C_EquipmentServices?.Name}
                    disabled
                    isFillable={false}
                    isHalf={true}
                    icon={null}
                  />
                  <Input
                    type="text"
                    name="recurrence"
                    LabelName={t("Recurrence")}
                    defaultValue={InfoRNC.RNC.C_Recurrence?.Name}
                    disabled
                    isFillable={false}
                    isHalf={true}
                    icon={null}
                  />
                </Left>
                <Right>
                  <Input
                    type="text"
                    name="createdBy"
                    LabelName={t("Created By")}
                    defaultValue={InfoRNC.RNC.CreatedBy?.Name}
                    isFillable={false}
                    disabled
                    isHalf={true}
                    icon={null}
                  />
                  <Input
                    type="text"
                    name="changeType"
                    LabelName={t("Change Type")}
                    defaultValue={InfoRNC.RNC.C_ChangeType?.Name}
                    disabled
                    isFillable={false}
                    isHalf={true}
                    icon={null}
                  />
                  <Input
                    type="text"
                    name="category"
                    LabelName={t("NC Category")}
                    defaultValue={InfoRNC.RNC.C_RNCCategory?.Name}
                    disabled
                    isFillable={false}
                    isHalf={true}
                    icon={null}
                  />
                  <DatePicker
                    name="occurrenceDate"
                    LabelName={t("NC Occurrence Date")}
                    InitDate={InfoRNC.RNC.C_RNCOccurrenceDate && new Date(InfoRNC.RNC.C_RNCOccurrenceDate)}
                    isFillable={InfoRNC.RNC.C_RNCOccurrenceDate !== null ? false : true}
                    disabled={InfoRNC.RNC.C_RNCOccurrenceDate !== null ? true : false}
                    isHalf={true}
                    icon={null}
                  />
                  <DatePicker
                    name="limitDateForResolution"
                    LabelName={t("Limit Date for Resolution")}
                    InitDate={InfoRNC.RNC.C_LimitDateforResolution && new Date(InfoRNC.RNC.C_LimitDateforResolution)}
                    isFillable={false}
                    disabled={true}
                    isHalf={true}
                    icon={null}
                  />
                </Right>
                <FullRow>
                  <TextArea
                    placeholder=""
                    name="comments"
                    LabelName={t("Comments")}
                    defaultValue={InfoRNC.RNC.C_Comments}
                    disabled={false}
                    isFillable={true}
                    isHalf={false}
                    icon={null}
                  />
                </FullRow>

                <Section text={t("Immediate Action")} />
                <Left>
                  <Input
                    type="text"
                    name="actionName"
                    LabelName={t("Immediate Action Name")}
                    defaultValue={InfoRNC.RNC.C_ImmediateActionName}
                    isFillable={InfoRNC.RNC.C_ImmediateActionName !== "" && InfoRNC.RNC.C_ImmediateActionName !== null ? false : true}
                    disabled={InfoRNC.RNC.C_ImmediateActionName !== "" && InfoRNC.RNC.C_ImmediateActionName !== null ? true : false}
                    isHalf={true}
                    icon={null}
                  />
                  <TextArea
                    placeholder=""
                    name="actionDescription"
                    LabelName={t("Immediate Action Description")}
                    defaultValue={InfoRNC.RNC.C_ImmediateActionDescription}
                    isFillable={InfoRNC.RNC.C_ImmediateActionDescription !== "" && InfoRNC.RNC.C_ImmediateActionDescription !== null ? false : true}
                    disabled={InfoRNC.RNC.C_ImmediateActionDescription !== "" && InfoRNC.RNC.C_ImmediateActionDescription !== null ? true : false}
                    isHalf={true}
                    icon={null}
                  />
                </Left>
                <Right>
                  <CreatableSelect
                    name="implementationImmediateAction"
                    LabelName={t("Implementation Immediate Action")}
                    defaultValue={InfoRNC.RNC.C_ImplementationImmediateAction?.id}
                    currentValue={InfoRNC.RNC.C_ImplementationImmediateAction?.id}
                    isFillable={InfoRNC.RNC.C_ImplementationImmediateAction?.id === undefined ? true : false}
                    disabled={InfoRNC.RNC.C_ImplementationImmediateAction?.id === undefined ? false : true}
                    isHalf={true}
                    options={Implementation}
                    icon={null}
                  />
                  <DatePicker
                    name="immediateActionDueDate"
                    LabelName={t("Immediate Action Due Date")}
                    InitDate={InfoRNC.RNC.C_ImmediateActionDueDateNCC && new Date(InfoRNC.RNC.C_ImmediateActionDueDateNCC)}
                    isFillable={InfoRNC.RNC.C_ImmediateActionDueDateNCC !== null ? false : true}
                    disabled={InfoRNC.RNC.C_ImmediateActionDueDateNCC !== null ? true : false}
                    isHalf={true}
                    icon={null}
                  />

                </Right>

                <Section text={t("Cause Analysis")} />
                <Left>
                  <Input
                    type="text"
                    name="rootCauseAnalysis"
                    LabelName={t("Root Cause Analysis")}
                    defaultValue={InfoRNC.RNC.C_RootCauseAnalysis}
                    isFillable={InfoRNC.RNC.C_RootCauseAnalysis !== null && InfoRNC.RNC.C_RootCauseAnalysis !== "" ? false : true}
                    disabled={InfoRNC.RNC.C_RootCauseAnalysis !== null && InfoRNC.RNC.C_RootCauseAnalysis !== "" ? true : false}
                    isHalf={true}
                    icon={null}
                  />
                  <Input
                    type="text"
                    name="causeAnalysisResponsible"
                    LabelName={t("Cause Analysis Responsible")}
                    defaultValue={InfoRNC.RNC.C_CauseAnalysisResponsibleRnc?.Name}
                    isFillable={false}
                    disabled
                    isHalf={true}
                    icon={null}
                  />
                </Left>
                <Right>
                  <CreatableSelect
                    name="qualityToolMethod"
                    LabelName={t("Quality Tool / Method")}
                    defaultValue={InfoRNC.RNC.C_QualityToolMethod?.id}
                    currentValue={InfoRNC.RNC.C_QualityToolMethod?.id}
                    isFillable={InfoRNC.RNC.C_QualityToolMethod?.id === undefined ? true : false}
                    disabled={InfoRNC.RNC.C_QualityToolMethod?.id === undefined ? false : true}
                    isHalf={true}
                    options={QualityToolMethod}
                    icon={null}
                  />
                  <DatePicker
                    name="rootCauseAnalysisLimitDate"
                    LabelName={t("Root Cause Analysis Limit Date")}
                    InitDate={InfoRNC.RNC.C_RootCauseAnalysisLimitDate && new Date(InfoRNC.RNC.C_RootCauseAnalysisLimitDate)}
                    isFillable={InfoRNC.RNC.C_RootCauseAnalysisLimitDate !== null ? false : true}
                    disabled={InfoRNC.RNC.C_RootCauseAnalysisLimitDate !== null ? true : false}
                    isHalf={true}
                    icon={null}
                  />
                </Right>

                <Section text={t("Action Plan Corrective")} />

                {InfoRNC.CorrectiveActions.length === 0 && (
                  <>
                    <Left>
                      <Input
                        type="text"
                        name={`correctiveActionCreate.Name`}
                        LabelName={t("Corrective Action Name")}
                        isFillable={true}
                        disabled={false}
                        isHalf={true}
                        icon={null}
                      />
                    </Left>
                    <Right>
                      <DatePicker
                        name={`correctiveActionCreate.DueDate`}
                        LabelName={t("Corrective Action Due Date")}
                        InitDate={null}
                        isFillable={true}
                        disabled={false}
                        isHalf={true}
                        icon={null}
                      />

                    </Right>
                    <TextArea
                      placeholder=""
                      name={`correctiveActionCreate.Description`}
                      LabelName={t("Corrective Action Description")}
                      isFillable={true}
                      disabled={false}
                      isHalf={false}
                      icon={null}
                    />
                    <TextArea
                      placeholder=""
                      name={`correctiveActionCreate.Evidence`}
                      LabelName={t("Corrective Action Evidence")}
                      isFillable={true}
                      disabled={false}
                      isHalf={false}
                      icon={null}
                    />
                    <TextArea
                      placeholder=""
                      name={`correctiveActionCreate.Comments`}
                      LabelName={t("Corrective Action Comments")}
                      isFillable={true}
                      disabled={false}
                      isHalf={false}
                      icon={null}
                    />
                  </>
                )}

                {InfoRNC.CorrectiveActions.length >= 1 && (
                  <TextArea
                    placeholder=""
                    name={`correctiveActionComments`}
                    LabelName={t("Corrective Action Comments")}
                    isFillable={true}
                    disabled={false}
                    isHalf={false}
                    icon={null}
                  />
                )}

                {InfoRNC.CorrectiveActions.slice(Math.max(InfoRNC.CorrectiveActions.length - 5, 0)).map((correctiveAction, index) => (index < 5) && (
                  <Corrective key={index} >
                    <Left>
                      <Input
                        type="text"
                        name={`correctiveAction[${index}].Name`}
                        LabelName={t("Corrective Action Name")}
                        defaultValue={correctiveAction.Name}
                        isFillable={correctiveAction.Name !== "" && correctiveAction.Name !== null ? false : true}
                        disabled={correctiveAction.Name !== "" && correctiveAction.Name !== null ? true : false}
                        isHalf={true}
                        icon={null}
                      />
                    </Left>
                    <Right>
                      <Input
                        type="text"
                        name={`correctiveAction[${index}].Responsible`}
                        LabelName={t("Corrective Action Responsible")}
                        defaultValue={correctiveAction.C_Responsible?.Name}
                        isFillable={false}
                        disabled={true}
                        isHalf={true}
                        icon={null}
                      />
                    </Right>
                    <TextArea
                      placeholder=""
                      name={`correctiveAction[${index}].Description`}
                      LabelName={t("Corrective Action Description")}
                      defaultValue={correctiveAction.Description}
                      isFillable={false}
                      disabled={true}
                      isHalf={false}
                      icon={null}
                    />
                    <Left>
                      <TextArea
                        placeholder=""
                        name={`correctiveAction[${index}].Evidence`}
                        LabelName={t("Corrective Action Evidence")}
                        defaultValue={correctiveAction.C_Evidence}
                        isFillable={false}
                        disabled={true}
                        isHalf={true}
                        icon={null}
                      />
                    </Left>
                    <Right>
                      <DatePicker
                        name={`correctiveAction[${index}].DueDate`}
                        LabelName={t("Corrective Action Due Date")}
                        InitDate={correctiveAction.DueDate && new Date(correctiveAction.DueDate)}
                        isFillable={correctiveAction.DueDate !== null ? false : true}
                        disabled={correctiveAction.DueDate !== null ? true : false}
                        isHalf={true}
                        icon={null}
                      />

                    </Right>

                    {/* <Section text="　" /> */}

                  </Corrective>

                ))}

                <Section text={t("Effectiveness")} />
                <Left>
                  <CreatableSelect
                    name="effectiveness"
                    LabelName={t("Effectiveness")}
                    defaultValue={InfoRNC.RNC?.C_CorrectiveActionEffectiveness.toString()}
                    currentValue={InfoRNC.RNC?.C_CorrectiveActionEffectiveness.toString()}
                    isFillable={false}
                    disabled={false}
                    isHalf={true}
                    options={[{
                      name: 'Yes',
                      value: 'true'
                    },
                    {
                      name: 'No',
                      value: 'false'
                    }]}
                    icon={null}
                  />

                  <DatePicker
                    name="effectivenessDate"
                    LabelName={t("Effectiveness Date")}
                    InitDate={InfoRNC.RNC?.C_CorrectiveActionEffectivenessDate && new Date(InfoRNC.RNC?.C_CorrectiveActionEffectivenessDate)}
                    isFillable={InfoRNC.RNC?.C_CorrectiveActionEffectivenessDate !== null ? false : true}
                    disabled={InfoRNC.RNC?.C_CorrectiveActionEffectivenessDate !== null ? true : false}
                    isHalf={true}
                    icon={null}
                  />
                </Left>
                <Right>
                  <Input
                    type="text"
                    name="effectivenessResponsible"
                    LabelName={t("Effectiveness Responsible")}
                    defaultValue={InfoRNC.RNC?.C_CorrectiveEffectivnessResponsible?.Name}
                    isFillable={false}
                    disabled
                    isHalf={true}
                    icon={null}
                  />
                  <TextArea
                    placeholder=""
                    name="effectivenessEvidence"
                    LabelName={t("Effectiveness Evidence")}
                    defaultValue={InfoRNC.RNC?.C_EffectivenessEvidence}
                    isFillable={false}
                    disabled={true}
                    isHalf={true}
                    icon={null}
                  />
                </Right>
                <TextArea
                  placeholder=""
                  name="effectivenessComments"
                  LabelName={t("Effectiveness Comments")}
                  defaultValue={InfoRNC.RNC?.C_EffectivenessComments}
                  isFillable={InfoRNC.RNC?.C_EffectivenessComments !== "" && InfoRNC.RNC?.C_EffectivenessComments !== null ? false : true}
                  disabled={InfoRNC.RNC?.C_EffectivenessComments !== "" && InfoRNC.RNC?.C_EffectivenessComments !== null ? true : false}
                  isHalf={false}
                  icon={null}
                />

                {/* <Upload onUpload={submitFile} />

                {!!UploadedFiles.length && <FileList files={UploadedFiles} />}

                {!!DownloadFiles.length && <DownloadList files={[DownloadFiles[0]]} />} */}

                <Footer>
                  <FooterContainer>
                    <Button
                      data-sitekey="6LfrLCQcAAAAALMXjlLo4bJY3hFwnSXyIVlqEdPT"
                      data-callback='onSubmit'
                      disabled={Disabled}
                      type="submit">
                      {t("Send")}
                    </Button>
                  </FooterContainer>
                  {ShowWarning && (
                    <FooterErrorContainer>
                      {t("fields_need_to_be_filled")}
                    </FooterErrorContainer>
                  )}
                </Footer>
              </Form>
            </>)}
          {(Answered === true && IsExpired === false) && (<>
            <Container>
              <AnimatedIcon theme={Theme} color="green" />
              <br />
              <Text>
                {t("AnsweredText")}{AnsRNC.CreatedBy.Name}.<br />
                {AnsRNC.C_RNCID} <br />
                {AnsRNC.Name} <br />
                {t("Siemens-Energy Team Brazil")}
              </Text>
            </Container>
          </>)}
          {(IsExpired === true) && (<>
            <Container>
              <AnimatedIcon theme={Theme} color="red" />
              <br />
              <Text>
                {t("Dear")} {AnsRNC.C_NotifiedResponsibleName} <br />
                {t("ExpiredText")}{AnsRNC.CreatedBy.Name}. <br />
                {AnsRNC.C_RNCID} <br />
                {AnsRNC.Name} <br />
                {t("Siemens-Energy Team Brazil")}
              </Text>
            </Container>
          </>)}
          {(IsSuccessfull === true) && (<>
            <Container>
              <AnimatedIcon theme={Theme} color="green" />
              <br />
              <Text>
                {t("Dear")} {AnsRNC.C_NotifiedResponsibleName} <br />
                {t("SuccessfullySent")}<br />
                {AnsRNC.C_RNCID} <br />
                {AnsRNC.Name} <br />
                {t("Siemens-Energy Team Brazil")}
              </Text>
            </Container>
          </>)}
          {(IsError === true) && (<>
            <Container>
              <AnimatedIcon theme={Theme} color="red" />
              <Text>{t("NC was not sent")}</Text>
            </Container>
          </>)}
          {(Found === false) && (<>
            <Container>
              <AnimatedIcon theme={Theme} color="red" />
              <Text>{t("NC was not found")}</Text>
            </Container>
          </>)}
        </RNCContainer>
      </Container>
    </ThemeProvider>
  );
};

export default UpdateRNC;
