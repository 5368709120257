import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import { useParams } from 'react-router-dom';
import { Container, Footer, Title, Subtitle, Text, Left, CSSContainer, Button, FooterContainer, FooterErrorContainer } from './styles';
import { DefaultTheme, ThemeProvider } from 'styled-components';
import * as Yup from 'yup';
import { useTranslation } from "react-i18next";

import TextArea from '../../components/TextArea';
import getValidationErrors from '../../utils/getValidationErrors';
import api from '../../services/api';
import addDays from '../../utils/addDays';
import Header from '../../components/Header';
import AnimatedIcon from '../../components/AnimatedIcon';
import Rate from '../../components/Rate'
import InputCSS from '../../components/InputCSS'

import usePersistedState from '../../utils/usePersistedState';
import GlobalStyle from '../../styles/global';
import dark from '../../styles/themes/dark';
import light from '../../styles/themes/light';
import Section from '../../components/Section';
import isEmpty from '../../utils/isEmpty';

// interfaces

interface fillCSS { // interface de preenchimento
  entities: Array<{
    id: string;
    C_Project: {
      id: string;
      Name: string;
    };
    C_Client: {
      id: string;
      Name: string;
    };
    C_RelatedProject: {
      C_ScopeDescription: string;
    };
    C_Comments: string;
  }>;
}
interface answeredCss { // interface de validação
  id: string;
  // Name: string;  // tirar no back
  // SYSID: string;
  CreatedBy: {
    Name: string;
  }
  C_RelatedContact: {
    Name: string;
  }
  C_AlreadyAnswered: Boolean | null;
  CreatedOn: string;
}

interface updateCSS { // interface de atualização
  id: string;
  question1: number;
  question2: number;
  comments: string;
}

const UpdateCss: React.FC = () => {
  // funcionamento do darkmode
  const [Theme, setTheme] = usePersistedState<DefaultTheme>('theme', light);
  const toggleTheme = () => {
    setTheme(Theme.title === 'light' ? dark : light)
  }
  const { t, i18n, ready } = useTranslation();

  const { id } = useParams<any>();
  const formRef = useRef<FormHandles>(null);
  const [InfoCSS, setInfoCSS] = useState<fillCSS>({} as fillCSS);
  const [AnsCSS, setAnsCSS] = useState<answeredCss>({} as answeredCss);
  const [Answered, setAnswered] = useState<Boolean>(false); // guarda se o relatório já foi respondido
  const [IsExpired, setIsExpired] = useState<Boolean>(false); // guarda se está expirado
  const [IsSuccessfull, setIsSuccessfull] = useState<Boolean>(false); // guarda se o Report foi respondido corretamente
  const [IsError, setIsError] = useState<Boolean>(false); // guarda se ocorreu algum erro
  const [Found, setFound] = useState<Boolean>(); // guarda se foi encontrado o Report

  const [Disabled, setDisabled] = useState<any>(false);

  const [ShowWarning, setShowWarning] = useState<any>(false);

  useEffect(() => {
    setShowWarning(false);
    document.title = "Evoluenergy - Customer Satisfaction Survey"
    async function loadTransactions(): Promise<void> {
      // Faz uma busca no Clarizen e verifica se a pesquisa é válida(já respondido, data de expiração)
      try {
        const answeredResp = await api.get(`/css-info/${id}`);
        const ansResp: answeredCss = answeredResp.data;
        setAnsCSS(ansResp);
        if (!answeredResp.data.hasOwnProperty('error')) {
          setFound(true);
          const cssAnsweredResp: answeredCss = answeredResp.data;

          // set data de expiração
          let limitDate: Date = addDays(
            new Date(cssAnsweredResp.CreatedOn),
            30
          );
          let today: Date = new Date();

          setAnswered(false);

          if (cssAnsweredResp.C_AlreadyAnswered !== true && !(today.getTime() > limitDate.getTime())) {
            const response = await api.get(`/css/${id}`);
            const cssResp: fillCSS = response.data;
            setInfoCSS(cssResp);
          }

          // valida se está expirado ou não
          else if (today.getTime() > limitDate.getTime()) {
            setIsExpired(true);
          }

          // valida se já está respondido
          if (cssAnsweredResp.C_AlreadyAnswered === true) {
            setAnswered(cssAnsweredResp.C_AlreadyAnswered)
          }
        } else {
          setFound(false);
        }
      } catch (err) {
        setFound(false);
      }
    }

    loadTransactions();
  }, [id]);

  const handleSubmit = useCallback(
    async (data: updateCSS) => {
      setDisabled(true); //  botão de enviar torna-se disabled após submit
      console.log("submit");

      try {
        // usa-se Yup para validação dos campos
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          question1: Yup.string().required(t("WarningMandatoryField")),
          question2: Yup.string().required(t("WarningMandatoryField")),
          comments: Yup.string().required(t("WarningComments")).max(2000, t("WarningMaxChar"))
        });

        await schema.validate(data, {
          abortEarly: false,
        });


        const {
          question1,
          question2,
          comments

        } = data;
        console.log(data);

        let updateObj: updateCSS = {
          id: InfoCSS?.entities[0]?.id,
          question1,
          question2,
          comments
        };

        console.log(updateObj);

        const respUpdate = await api.post('/css', updateObj);
        console.log(respUpdate);

        if (!respUpdate.data.hasOwnProperty('errorCode')) {
          setIsSuccessfull(true);
        } else {
          setIsError(true);
        }
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          setDisabled(false);
          setShowWarning(true);
          const errors = getValidationErrors(err);
          console.log(errors);
          formRef.current?.setErrors(errors);
        }
      }
    },
    [InfoCSS],
  );

  const optionsQuestion1 = [
    {
      id: "1q1",
      name: "q1",
      value: "/C_C_CustomerSurveyQuestion1/1"
    },
    {
      id: "1q2",
      name: "q1",
      value: "/C_C_CustomerSurveyQuestion1/2"
    },
    {
      id: "1q3",
      name: "q1",
      value: "/C_C_CustomerSurveyQuestion1/3"
    },
    {
      id: "1q4",
      name: "q1",
      value: "/C_C_CustomerSurveyQuestion1/4"
    },
    {
      id: "1q5",
      name: "q1",
      value: "/C_C_CustomerSurveyQuestion1/5"
    },
    {
      id: "1q6",
      name: "q1",
      value: "/C_C_CustomerSurveyQuestion1/6"
    },
    {
      id: "1q7",
      name: "q1",
      value: "/C_C_CustomerSurveyQuestion1/7"
    },
    {
      id: "1q8",
      name: "q1",
      value: "/C_C_CustomerSurveyQuestion1/8"
    },
    {
      id: "1q9",
      name: "q1",
      value: "/C_C_CustomerSurveyQuestion1/9"
    },
    {
      id: "1q10",
      name: "q1",
      value: "/C_C_CustomerSurveyQuestion1/10"
    },
  ];

  const optionsQuestion2 = [
    {
      id: "2q1",
      name: "q2",
      value: "/C_C_CustomerSurveyQuestion2/1"
    },
    {
      id: "2q2",
      name: "q2",
      value: "/C_C_CustomerSurveyQuestion2/2"
    },
    {
      id: "2q3",
      name: "q2",
      value: "/C_C_CustomerSurveyQuestion2/3"
    },
    {
      id: "2q4",
      name: "q2",
      value: "/C_C_CustomerSurveyQuestion2/4"
    },
    {
      id: "2q5",
      name: "q2",
      value: "/C_C_CustomerSurveyQuestion2/5"
    },
    {
      id: "2q6",
      name: "q2",
      value: "/C_C_CustomerSurveyQuestion2/6"
    },
    {
      id: "2q7",
      name: "q2",
      value: "/C_C_CustomerSurveyQuestion2/7"
    },
    {
      id: "2q8",
      name: "q2",
      value: "/C_C_CustomerSurveyQuestion2/8"
    },
    {
      id: "2q9",
      name: "q2",
      value: "/C_C_CustomerSurveyQuestion2/9"
    },
    {
      id: "2q10",
      name: "q2",
      value: "/C_C_CustomerSurveyQuestion2/10"
    },
  ];

  return (
    <ThemeProvider theme={Theme}>
      <GlobalStyle />
      <Header size="small" toggleTheme={toggleTheme} theme={Theme} />
      <Container>
        <CSSContainer>
          {(!isEmpty(InfoCSS) && Answered !== true && Found === true && IsExpired === false && IsSuccessfull === false && IsError === false) && (
            <>
              <Form ref={formRef} onSubmit={handleSubmit}>
                <Container>
                  <Title>{t("CSS Title")}</Title>
                  <Subtitle>{t("CSS SubTitle")}</Subtitle>

                  <Section text={t("Project Data")} />

                  <Left>
                    <InputCSS
                      type="text"
                      name="client"
                      LabelName={t("Client")}
                      defaultValue={InfoCSS?.entities[0]?.C_Client?.Name}
                      disabled
                      isFillable={false}
                      isHalf={true}
                      icon={null}
                    />
                    <InputCSS
                      type="text"
                      name="projectName"
                      LabelName={t("Project")}
                      defaultValue={InfoCSS?.entities[0]?.C_Project?.Name}
                      disabled
                      isFillable={false}
                      isHalf={true}
                      icon={null}
                    />
                    <InputCSS
                      type="text"
                      name="objective"
                      LabelName={t("Objective")}
                      defaultValue={InfoCSS?.entities[0]?.C_RelatedProject?.C_ScopeDescription}
                      disabled
                      isFillable={false}
                      isHalf={true}
                      icon={null}
                    />
                  </Left>


                  <Section text={t("Rating Criteria")} />
                  <p className="textarea">
                    {t("Rating Criteria Text1")}<br />
                    {t("Rating Criteria Text2")}

                  </p>

                  <Section text={t("Questions")} />

                  <Rate
                    name="question1"
                    LabelName={t("Question1")}
                    // defaultValue = {"Objective Name here"}//{InfoCSS.C_ProjectCSS.Name}
                    isFillable={false}
                    isHalf={true}
                    icon={null}
                    options={optionsQuestion1}
                  />

                  <Rate
                    name="question2"
                    LabelName={t("Question2")}
                    isFillable={false}
                    isHalf={true}
                    icon={null}
                    options={optionsQuestion2}
                  />

                  <p className="space" />

                  <TextArea
                    placeholder=""
                    name="comments"
                    LabelName={t("Specific Comments / Suggestions / Praise")}
                    defaultValue={InfoCSS?.entities[0]?.C_Comments}
                    isFillable={true}
                    isHalf={false}
                    icon={null}
                  />

                  <Footer>
                    <FooterContainer>
                      <Button
                        data-sitekey="6LfrLCQcAAAAALMXjlLo4bJY3hFwnSXyIVlqEdPT"
                        data-callback='onSubmit'
                        disabled={Disabled}
                        type="submit">
                        {t("Send")}
                      </Button>
                    </FooterContainer>
                    {ShowWarning && (
                      <FooterErrorContainer>
                        {t("fields_need_to_be_filled")}
                      </FooterErrorContainer>
                    )}
                  </Footer>

                </Container>
              </Form>
            </>)}
          {(Answered === true && IsExpired === false) && (<>
            <Container>
              <AnimatedIcon theme={Theme} color="green" />
              <br />
              <Text>
                {t("CSSAnswered")}
                {AnsCSS.CreatedBy.Name}.
                <br />
                {t("Siemens-Energy Team Brazil")}
              </Text>
            </Container>
          </>)}
          {(IsExpired === true) && (<>
            <Container>
              <AnimatedIcon theme={Theme} color="red" />
              <br />
              <Text>
                {t("Dear")}
                {AnsCSS.C_RelatedContact.Name}
                <br />
                {t("CSSExpired")}
                {AnsCSS.CreatedBy.Name}.
                <br />
                {t("Siemens-Energy Team Brazil")}
              </Text>
            </Container>
          </>)}
          {(IsSuccessfull === true) && (<>
            <Container>
              <AnimatedIcon theme={Theme} color="green" />
              <br />
              <Text>
                {t("Dear")}
                {AnsCSS.C_RelatedContact.Name}
                <br />
                {t("CSSSuccessfullySent")}
                <br />
                {t("Siemens-Energy Team Brazil")}
              </Text>
            </Container>
          </>)}
          {(IsError === true) && (<>
            <Container>
              <AnimatedIcon theme={Theme} color="red" />
              <Text>{t("CSS was not sent")}</Text>
            </Container>
          </>)}
          {(Found === false) && (<>
            <Container>
              <AnimatedIcon theme={Theme} color="red" />
              <Text>{t("CCS was not found")}</Text>
            </Container>
          </>)}

        </CSSContainer>
      </Container>
    </ThemeProvider>

  );
};

export default UpdateCss;
