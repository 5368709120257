import axios from 'axios';

const api = axios.create({
  baseURL: "https://b5ktaa3gui.execute-api.us-east-2.amazonaws.com/dev",

  // baseURL: 'https://t2xetqb6sa.execute-api.us-east-2.amazonaws.com/prod',
  // baseURL: 'https://cafxxfld0b.execute-api.us-east-2.amazonaws.com/dev'
});

export default api;
