import React, {
  InputHTMLAttributes,
  useEffect,
  useRef,
  useState,
  useCallback,
} from 'react';
import { IconBaseProps } from 'react-icons';
import { FiAlertCircle } from 'react-icons/fi';
import { useField } from '@unform/core';

import { Container, ContainerField, Error, Label } from './styles';
import { useTranslation } from "react-i18next";

interface InputProps extends InputHTMLAttributes<HTMLSelectElement> {
  LabelName: string;
  name: string;
  icon: React.ComponentType<IconBaseProps> | null;
  isFillable: boolean;
  isHalf: boolean;
  options: Array<{
    value: string;
    name: string;
    default?: true | false;
  }>;
  currentValue?: string;
  colorOptionalDefaul?: 'optional' | 'api';
}

const CreatableSelect: React.FC<InputProps> = ({
  name,
  LabelName,
  isFillable,
  isHalf,
  options,
  icon: Icon,
  currentValue,
  colorOptionalDefaul,
  ...rest
}: InputProps) => {
  const selectRef = useRef<HTMLSelectElement>(null);
  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);

  const { fieldName, defaultValue, error, registerField } = useField(name);
  
  const { t, i18n, ready } = useTranslation();

  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);

    setIsFilled(!!selectRef.current?.value);
  }, []);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  let optionValue = options.map(function (option, i) {
    return <option key={i} value={option.value} selected={currentValue === option.value ? true : false}>{option.name}</option>
  });

  return (
    <>
      <ContainerField>
        <Label isHalf={isHalf}>{LabelName}:</Label>
        <Container error={!!error} isFilled={isFilled} isFocused={isFocused} isFillable={isFillable}>
          {Icon && <Icon size={20} />}
          <select
            onFocus={handleInputFocus}
            onBlur={handleInputBlur}
            defaultValue={defaultValue}
            ref={selectRef}
            {...rest}
          >
            {(currentValue === null || currentValue === "" || currentValue === undefined) && (<option value="" selected={true}>{t("choose here")}</option>)}
            {optionValue}
          </select>

          {error && (
            <Error title={error}>
              <FiAlertCircle size={20} color="#c53030" />
            </Error>
          )}
        </Container>
      </ContainerField>
      <br />
    </>
  );
};

export default CreatableSelect;
